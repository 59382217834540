import React from "react";
import "../App.css";

function Footer() {
  return (
    <footer>
      <div>
        <p>Created by Kevin Akbar Adhiguna</p>
      </div>
    </footer>
  );
}

export default Footer;
